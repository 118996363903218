import React from 'react';

import { Flex, Box } from '@rebass/grid';

import CatOnWaves from '../CatOnWaves';
import Flowers from '../Flowers/BottomWaves';

const CallToAction = () => {
  return (
    <Flex as="section" flexDirection="column" alignItems="center">
      <Box
        width={1920}
        css={`
          position: relative;
        `}
      >
        <Flowers />
        <CatOnWaves />
      </Box>
    </Flex>
  );
};

export default CallToAction;
