import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import withLayout from '../layout';
import { CallToAction } from '../components/menuPage';

const Menu = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      {mounted && (
        <Helmet>
          <script src="https://cdn.upmenu.com/media/upmenu-widget.js"></script>
        </Helmet>
      )}
      <div id="upmenu-widget"></div>
      <CallToAction />
    </>
  );
};

Menu.propTypes = {};

const customProps = {
  localeKey: 'menu',
  keywords: ['menu'],
};

export default withLayout(customProps)(Menu);
