import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Flex, Box } from '@rebass/grid';
import {
  compose,
  height,
  color,
  fontSize,
  maxWidth,
  borderBottom,
  borderColor,
} from 'styled-system';

const ItemRow = ({ name, description, price }) => (
  <Flex justifyContent="space-between" mt={!name && '5px'}>
    <Box
      css={`
        p {
          margin: 0;
          font-family: UnicaOne-regular;
        }
      `}
    >
      {!!name && (
        <p css={fontSize} fontSize={16}>
          {name}
        </p>
      )}
      <p css={fontSize} fontSize={13}>
        {description}
      </p>
    </Box>
    <Box
      css={`
        ${compose(
          color,
          fontSize,
        )}
        white-space: nowrap;
        line-height: ${!!name && 1.75};
      `}
      color="green"
      fontSize={16}
      ml={10}
    >
      {price}
    </Box>
  </Flex>
);

const Item = ({ additives, ...props }) => (
  <Box
    css={compose(
      borderBottom,
      borderColor,
    )}
    py={20}
    borderBottom="1px solid"
    borderColor="green"
  >
    <ItemRow {...props} />
    {!!additives.length &&
      additives.map((additive, index) => <ItemRow key={index} {...additive} />)}
  </Box>
);

const MenuGroup = ({ section, items }) => {
  return (
    <Box css={maxWidth} mb={50} mx={{ mobile: 36, laptop: 10 }} width={[1, 400]} maxWidth={400}>
      <Flex
        css={compose(
          height,
          color,
          fontSize,
        )}
        height={44}
        bg="green"
        color="white"
        justifyContent="center"
        alignItems="center"
        fontSize={18}
      >
        <FormattedMessage id={`menu.${section}`} />
      </Flex>
      {items.map(item => (
        <Item key={item.name} {...item} />
      ))}
    </Box>
  );
};

MenuGroup.propTypes = {
  section: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      price: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
};

export default MenuGroup;
