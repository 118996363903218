import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, space, position, justifyContent } from 'styled-system';

import Button from '../Button';
import Link from '../Link';

import FacebookIcon from '../../assets/facebook-icon';

import { FACEBOOK_LINK } from '../../constants';

const Subheader = () => {
  return (
    <>
      <h3 css={space} mt={50} mb={50} mx={36}>
        <FormattedMessage id="menu.header1" />
        <br />
        <FormattedMessage id="menu.header2" />
        <br />
        <FormattedMessage id="menu.header3" />
      </h3>
      <p
        css={`
          ${space}
          text-align: center;
          * {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.black};
          }
        `}
        mt={0}
        mb={20}
      >
        <FormattedMessage id="menu.go to facebook" />
      </p>
      <Button
        as={Link}
        external
        to={FACEBOOK_LINK}
        css={`
          ${compose(justifyContent, space)}
          position: relative;
        `}
        justifyContent={{ mobile: 'space-between', laptop: 'center' }}
        px={{ mobile: 12, laptop: 0 }}
        mb={100}
      >
        <FacebookIcon
          white
          css={`
            ${position}
            left: 12px;
          `}
          position={{ mobile: 'static', laptop: 'absolute' }}
        />
        <FormattedMessage id="menu.see" />
      </Button>
    </>
  );
};

export default Subheader;
