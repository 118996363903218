import React, { useEffect } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import { injectIntl } from 'react-intl';

import { Flex } from '@rebass/grid';
import MenuGroup from './MenuGroup';

import { MENU_SECTIONS } from '../../constants';

const MenuComponent = (props) => {
  const {
    intl: { locale },
  } = props;

  useEffect(() => {
    locale === 'en' && navigate('/pl/menu');
  }, []);

  const getAdditives = (additiveName, items) =>
    items
      .filter((item) => item.node.type === additiveName)
      .map(({ node: { description, price } }) => ({ description, price }));

  const getItemsInSection = (section, items) =>
    items
      .map((item) => item.node)
      .filter((item) => item.type === section)
      .map((item) => ({ ...item, additives: getAdditives(item.additives, items) }));

  return (
    <StaticQuery
      query={menuQuery}
      render={(data) => {
        const menuItems = data[locale].edges;
        return (
          <Flex flexWrap="wrap" justifyContent="center">
            {MENU_SECTIONS.map((section) => (
              <MenuGroup
                key={section}
                section={section}
                items={getItemsInSection(section, menuItems)}
              />
            ))}
          </Flex>
        );
      }}
    />
  );
};

const menuQuery = graphql`
  query {
    en: allEnCsv {
      edges {
        node {
          name
          description
          price
          type
          additives
        }
      }
    }

    pl: allPlCsv {
      edges {
        node {
          name
          description
          price
          type
          additives
        }
      }
    }
  }
`;

export default injectIntl(MenuComponent);
